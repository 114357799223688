// import React, { useState, useEffect } from "react";
// import { Link } from "gatsby";
// import PageWrapper from "../components/PageWrapper";
// import { Select } from "../components/Core";
// import axios from "axios";


// const defaultJobs = [
//   { value: "pd", label: "Product Designer" },
//   { value: "gd", label: "Graphics Designer" },
//   { value: "fd", label: "Frontend Developer" },
//   { value: "bd", label: "Backend Developer" },
//   { value: "cw", label: "Content Writer" },
// ];

// const DashboardJobs = () => {
//   const [jobs, setJobs] = useState([]);

//   useEffect(() => {
//     axios
//       .get("http://localhost:8000/api/job-list")
//       .then((response) => {
//         if (response.data.success) {
//           setJobs(response.data.data);
//         } else {
//           console.error("Failed to fetch categories");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching categories:", error);
//       });
//   }, []);

//   const deleteJob = (jobId) => {
//     axios
//       .delete(`http://localhost:8000/api/jobs/${jobId}`)
//       .then((response) => {
//         if (response.data.success) {
//           setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
//         } else {
//           console.error("Failed to delete job");
//         }
//       })
//       .catch((error) => {
//         console.error("Error deleting job:", error);
//       });
//   };

//   return (
//     <>
//       <PageWrapper
//         headerConfig={{
//           button: "profile",
//           isFluid: true,
//           bgClass: "bg-default",
//           reveal: false,
//         }}
//       >
//         <div className="dashboard-main-container mt-25 mt-lg-31">
//           <div className="container">
//             <div className="mb-18">
//               <div className="row mb-11 align-items-center">
//                 <div className="col-lg-6 mb-lg-0 mb-4">
//                   <h3 className="font-size-6 mb-0">Posted Jobs (4)</h3>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
//                     <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job:</p>
//                     <div className="h-px-48">
//                       <Select
//                         options={defaultJobs}
//                         className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
//                         border={false}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="bg-white shadow-8 pt-7 rounded pb-9 px-11">
//                 <div className="table-responsive ">
//                   <table className="table table-striped">
//                     <thead>
//                       <tr>
//                         <th
//                           scope="col"
//                           className="pl-0 border-0 font-size-4 font-weight-normal"
//                         >
//                           Name
//                         </th>
//                         <th
//                           scope="col"
//                           className="pl-4 border-0 font-size-4 font-weight-normal"
//                         >
//                           Job Type
//                         </th>
//                         <th
//                           scope="col"
//                           className="pl-4 border-0 font-size-4 font-weight-normal"
//                         >
//                           City
//                         </th>
//                         <th
//                           scope="col"
//                           className="pl-4 border-0 font-size-4 font-weight-normal"
//                         >
//                           Created on
//                         </th>
//                         <th
//                           scope="col"
//                           className="pl-4 border-0 font-size-4 font-weight-normal"
//                         >
//                           Total Applicants
//                         </th>
//                         <th
//                           scope="col"
//                           className="pl-4 border-0 font-size-4 font-weight-normal"
//                         ></th>
//                         <th
//                           scope="col"
//                           className="pl-4 border-0 font-size-4 font-weight-normal"
//                         ></th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {jobs.map((job, index) => (
//                         <tr className="border border-color-2" key={index}>
//                           <th
//                             scope="row"
//                             className="pl-6 border-0 py-7 min-width-px-235"
//                           >
//                             <div className="">
//                               <Link
//                                 to="/job-details"
//                                 className="font-size-4 mb-0 font-weight-semibold text-black-2"
//                               >
//                                 {job.title}
//                               </Link>
//                             </div>
//                           </th>
//                           <td className="table-y-middle py-7 min-width-px-135">
//                             <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                               {job.job_type.title}
//                             </h3>
//                           </td>
//                           <td className="table-y-middle py-7 min-width-px-125">
//                             <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                               {job.location}
//                             </h3>
//                           </td>
//                           <td className="table-y-middle py-7 min-width-px-155">
//                             <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
//                               {job.created_at}
//                             </h3>
//                           </td>
//                           <td className="table-y-middle py-7 min-width-px-205">
//                             <h3 className="font-size-4 font-weight-bold text-black-2 mb-0">
//                               47
//                             </h3>
//                           </td>
//                           <td className="table-y-middle py-7 min-width-px-80">
//                             <Link
//                               to={`/job-edit/${job.id}`}
//                               className="font-size-3 font-weight-bold text-green text-uppercase"
//                             >
//                               Edit
//                             </Link>
//                           </td>
//                           <td className="table-y-middle py-7 min-width-px-100">
//                             <button
//                               onClick={() => deleteJob(job.id)}
//                               className="font-size-3 font-weight-bold text-red-2 text-uppercase"
//                             >
//                               Delete
//                             </button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </PageWrapper>
//     </>
//   );
// };

// export default DashboardJobs;


import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import axios from "axios";
import network from '../../constants/Network';
import GlobalContext from "../context/GlobalContext";


// const defaultJobs = [
//   { value: "pd", label: "Product Designer" },
//   { value: "gd", label: "Graphics Designer" },
//   { value: "fd", label: "Frontend Developer" },
//   { value: "bd", label: "Backend Developer" },
//   { value: "cw", label: "Content Writer" },
// ];

const DashboardJobs = () => {
  const [jobs, setJobs] = useState([]);
  const gContext = useContext(GlobalContext);
  const isMountedRef = useRef(false);
  const { user } = useContext(GlobalContext);
  const [filter, setFilter] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [totalJobCount, setTotalJobCount] = useState(0);

  const imageBaseUrl = `${network.serverip}/images/`;

  const [userId, setUserId] = useState('');

  const [paginationData, setPaginationData] = useState({
    total: 0,
    current_page: 1,
    last_page: 1,
    per_page: 10,
  });

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {

    if (userId) {
      const fetchData = async () => {
        await fetchJobs(userId);
      };

      fetchData();
    }

  }, [userId]);

  const handleFilterJob = async (filter) => {
    fetchJobs(userId, filter);
  };

  const jobFilterOptions = [
    { value: '', label: 'Clear Filter' },
    ...Array.from(
      new Map(
        allCategories
          .filter(item => item.id && item.title)
          .map(item => [item.id, {
            value: item.id,
            label: item.title
          }])
      ).values()
    )
  ];

  const fetchJobs = async (userId, filter, page = 1) => {
    try {

      const response = await axios.post(
        `${network.serverip}/api/hr-posted-joblist-forweb`,
        {
          userId: userId,
          job_category_id: filter,
          page: page
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success && isMountedRef.current) {
        const jobData = response.data.data || [];
        const categoriesData = response.data.allCategoriesPosted || [];
        setJobs(jobData);
        setAllCategories(categoriesData);
        setPaginationData(response.data.pagination);
        setTotalJobCount(response.data.totalJobCount);
        console.log('Fetched jobs:', jobData);
      } else if (!response.data.success) {
        console.error('Failed to fetch jobs');
      }

    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  // const handleDeleteJob = async (jobId) => {
  //   try {

  //     if (!userId) {
  //       gContext.toggleSignInModal();

  //       return
  //     }

  //     const formData = new FormData();
  //     formData.append('user_id', userId);
  //     formData.append('job_id', jobId);

  //     const response = await axios.post(
  //       `${network.serverip}/api/hr-posted-jobDelete-forweb`,
  //       formData,
  //       {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         }
  //       }
  //     );

  //     if (response.data.success && isMountedRef.current) {
  //       if (response.data.deleted_job) {
  //         setJobs(jobs.filter(job => job.id !== response.data.deleted_job.id));
  //         console.log('Deleted job:', response.data.deleted_job);
  //       } else {
  //         console.warn('Job deleted but response does not include deleted job data');
  //       }
  //     } else {
  //       console.error('Failed to delete job:', response.data.message || 'Unknown error');
  //     }

  //   } catch (error) {
  //     console.error('Error deleting job:', error);
  //   }
  // };

  const styles = dashboardJobsStyling();

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-33 mt-md-30">
          <div className="container">
            <div className="mb-18">
              <div className="row mb-11 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">{`Posted Jobs (${totalJobCount})`}</h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    <p className="font-size-4 mb-0 mr-6 py-2">Filter by Job Category:</p>
                    <div className="h-px-48">
                      {/* <Select
                        options={defaultJobs}
                        className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
                        border={false}
                      /> */}
                      <Select
                        options={jobFilterOptions}
                        className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center w-100"
                        border={false}
                        onChange={(selectedOption) => {
                          handleFilterJob(selectedOption.value);
                          setFilter(selectedOption.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-8 pt-7 rounded pb-9 px-11">
                <div className="table-responsive ">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Job Type
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Job category
                        </th>
                        {/* <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Location
                        </th> */}
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Created on
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >
                          Total Applicants
                        </th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-bold"
                        >Actions</th>
                        <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-normal"
                          aria-hidden="true"
                        ></th>
                        {/* <th
                          scope="col"
                          className="pl-5 border-0 font-size-4 font-weight-normal"
                          aria-hidden="true"
                        ></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {jobs.length === 0 ? (
                        <tr>
                          <td colSpan="100%" className="text-center py-4">
                            <p className="mb-0">No jobs found</p>
                          </td>

                        </tr>
                      ) : (<>
                        {
                          jobs.map((job, index) => (
                            <tr className="border border-color-2" key={job.id}>
                              <th scope="row"
                                className="table-y-middle py-7 min-width-px-90"
                              >
                                <Link
                                  to={`/job-details/?id=${job.id}`}
                                  className="font-size-3 mb-0 font-weight-semibold text-black-2"
                                >
                                  <span>
                                    {`${((paginationData.current_page - 1) * 10) + index + 1}`}
                                  </span>
                                </Link>
                              </th>
                              <td
                                className="table-y-middle py-7 min-width-px-70"
                              >
                                <div className="d-flex justify-content-start align-items-center">
                                  <button
                                    onClick={() => gContext.toggleImageViewModal(job?.image)}
                                    style={styles.imageViewButton}
                                  >
                                    <img
                                      src={job && job.image ? imageBaseUrl + job.image : ""}
                                      alt=""
                                      style={styles.imageContainer}
                                    />
                                  </button>
                                </div>
                              </td>
                              <td
                                className="table-y-middle py-7 min-width-px-125"
                              >
                                <Link
                                  to={`/job-details/?id=${job.id}`}
                                  className="font-size-3 mb-0 font-weight-semibold text-black-2"
                                >
                                  <span>
                                    {`${job.title}`}
                                  </span>
                                </Link>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-110">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.job_type?.title || ''}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-135">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.job_category?.title || ''}
                                </h3>
                              </td>
                              {/* <td className="table-y-middle py-7 min-width-px-125">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job.location?.title || ''}
                                </h3>
                              </td> */}
                              <td className="table-y-middle py-7 min-width-px-125">
                                <h3 className="font-size-3 font-weight-normal text-black-2 mb-0">
                                  {job?.created_at ?
                                    new Date(job.created_at).toLocaleDateString('default', {
                                      day: '2-digit',
                                      month: 'short',
                                      year: 'numeric'
                                    }).replace(/ /g, '-')
                                    : ''}
                                </h3>
                              </td>
                              <td className="table-y-middle py-7 min-width-px-155">
                                <Link to={`/dashboard-applicants/?id=${job.id}&title=${job.title}`}
                                  className="btn btn-outline-success rounded-3"
                                  style={styles.applicantsButtonStyle}
                                >
                                  {`Applicants (${job?.no_of_applicants || 0})`}
                                </Link>
                              </td>
                              <td className="table-y-middle py-7 pr-0">
                                <Link
                                  to={`/job-details/?id=${job.id}`}
                                  className="btn btn-success rounded-3"
                                  style={styles.viewButtonStyle}
                                >
                                  <i className="fas fa-eye"></i>
                                  <span className="pl-3">View</span>
                                </Link>
                              </td>
                              <td className="table-y-middle py-7">
                                <Link
                                  to={`/dashboard-job-edit/?id=${job.id}`}
                                  className="btn btn-info rounded-3"
                                  style={styles.editButtonStyle}
                                >
                                  <span className="btn-inner--icon"><i className="fas fa-pencil-alt"></i></span>
                                  <span className="pl-3">Edit</span>
                                </Link>
                              </td>
                              {/* <td className="table-y-middle py-7">
                                <button
                                  onClick={() => handleDeleteJob(job?.id)}
                                  className="btn btn-danger rounded-3 mr-2"
                                  style={styles.viewButtonStyle}
                                >
                                  <span className="btn-inner--icon"><i className="fas fa-trash-alt"></i></span>
                                  <span className="pl-3">Delete</span>
                                </button>
                              </td> */}

                            </tr>
                          ))
                        }
                      </>
                      )}

                    </tbody>
                  </table>
                </div>
                {/* Pagination Controls */}
                <div className="pagination w-100 d-flex justify-content-center align-items-center mt-5">
                  {paginationData && paginationData.current_page > 1 ? (
                    <button
                      onClick={() => fetchJobs(userId, filter, paginationData.current_page - 1)}
                      className="btn btn-outline-success rounded-3"
                      style={styles.previousButtonStyle}
                    >
                      <span className="btn-inner--icon"><i className="fas fa-chevron-left"></i></span>
                      <span className="pl-3">Previous</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-gray rounded-3"
                      style={styles.previousButtonStyle}
                      disabled
                    >
                      <span className="btn-inner--icon"><i className="fas fa-chevron-left"></i></span>
                      <span className="pl-3">Previous</span>
                    </button>
                  )}

                  {paginationData && (
                    <span className="ml-5 mr-5 text-success">
                      Page {paginationData.current_page} of {paginationData.last_page}
                    </span>
                  )}

                  {paginationData && paginationData.current_page < paginationData.last_page ? (
                    <button
                      onClick={() => fetchJobs(userId, filter, paginationData.current_page + 1)}
                      className="btn btn-outline-success rounded-3"
                      style={styles.nextButtonStyle}
                    >
                      <span className="pr-3">Next</span>
                      <span className="btn-inner--icon"><i className="fas fa-chevron-right"></i></span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-gray rounded-3"
                      style={styles.nextButtonStyle}
                      disabled
                    >
                      <span className="pr-3">Next</span>
                      <span className="btn-inner--icon"><i className="fas fa-chevron-right"></i></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardJobs;

function dashboardJobsStyling() {
  const styles = {

    imageContainer: {
      width: '3rem',
      height: '3rem',
      objectFit: 'cover',
      borderRadius: '5px',
    },
    applicantsButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    viewButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    editButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    deleteButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    previousButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '7rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    nextButtonStyle: {
      padding: '0.2rem 0.5rem',
      minWidth: '7rem',
      display: 'inline-flex',
      alignItems: 'center',
    },

    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },

  };

  return styles;
}

